<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Boards: "Tabuleiros",
      "BACK": "VOLTAR",
      "To start your board you need to donate to your sponsor.": "Para iniciar o seu tabuleiro você precisa realizar a doação para o seu patrocinador.",
      "Sponsor Information": "Informações do Patrocinador",
      "Donate": "Doar",
      "VIEW": "VER",
      "COMPLETED": "COMPLETO",
      "ENTER": "ENTRAR",
      "LOCKED": "BLOQUEADO",
    },
    es: {
      Boards: "Tableros",
      "BACK": "VOLVER",
      "To start your board you need to donate to your sponsor.": "Para comenzar su tablero, debe donar a su patrocinador.",
      "Sponsor Information": "Información de Patrocinador",
      "Donate": "Donar",
      "VIEW": "VER",
      "COMPLETED": "COMPLETO",
      "ENTER": "ENTRAR",
      "LOCKED": "BLOQUEADO",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      types: null,
      sponsor: false,
    };
  },
  methods: {
    getBoard() {
      this.board = null;
      this.types = null;

      api.get("boards").then((response) => {
        if (response.data.status == "success") {
          this.types = response.data.list;
        } else {
          this.$router.push("/");
        }
      });
    },
  },
  mounted() {
    this.getBoard();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex justify-content-between align-items-center"
        >
          <h4 class="mb-0 font-size-18">{{ t("Boards") }}</h4>
          <div class="ml-2">
            <button
              class="btn btn-light btn-sm ml-2"
              v-on:click="getBoard()"
              ><i class="lnr lnr-sync "></i></button
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="!types" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="dark"></b-spinner>
    </div>
    <div class="row" v-else-if="types && types.length > 0">
      <div
        class="col-md-3 mb-4"
        v-for="(row, index) in types"
        :key="index"
      >
        <template v-if="row.position.status == 'pending'">
          <router-link tag="a" :to="'/boards/' + row.position.id">
            <div class="card type m-0">
              <div
                class="card-body rounded text-center text-white"
                :class="row.id"
              >
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div
                  class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
                >
                  {{ t('VIEW') }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'active'">
          <router-link tag="a" :to="'/boards/' + row.position.id">
            <div class="card type m-0">
              <div
                class="card-body rounded text-center text-white"
                :class="row.id"
              >
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div
                  class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
                >
                  {{ t('VIEW') }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'completed'">
          <router-link tag="a" :to="'/boards/' + row.position.id">
            <div class="card type m-0">
              <div
                class="card-body rounded text-center text-white"
                :class="row.id"
              >
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div
                  class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
                >
                  {{ t("COMPLETED") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'enter'">
          <router-link tag="a" :to="'/boards/' + row.position.id">
            <div class="board card type m-0">
              <div
                class="card-body rounded text-center text-white"
                :class="row.id"
              >
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div
                  class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
                >
                  {{ t("ENTER") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'preview'">
          <router-link tag="a" :to="'/boards/' + row.position.id">
            <div class="board card type m-0">
              <div
                class="card-body rounded text-center text-white"
                :class="row.id"
              >
                <h4 class="m-0 text-white">{{ row.name }}</h4>
                <div class="type-donation">
                  {{ t('Donate') }} {{ row.donation | currency }}
                </div>
                <div
                  class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
                >
                  {{ t("ENTER") }}
                </div>
              </div>
            </div>
          </router-link>
        </template>
        <template v-else-if="row.position.status == 'locked'">
          <div class="card type m-0">
            <div
              class="card-body rounded text-center text-white"
              :class="row.id"
            >
              <h4 class="m-0 text-white">{{ row.name }}</h4>
              <div class="type-donation">
                {{ t('Donate') }} {{ row.donation | currency }}
              </div>
              <div
                class="type-status bg-white rounded text-dark font-size-12 font-weight-bold mt-2 py-1"
              >
                {{ t("LOCKED") }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.divisor {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.divisor span {
  display: inline-block;
  padding: 0 5px;
  background: #fff;
  font-size: 10px;
  letter-spacing: 2px;
  color: #888;
}
.divisor:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  top: 52%;
  height: 1px;
  background: #ccc;
}

.bronze {
  background: #cd7f32;
  background: linear-gradient(to bottom, #cd7f32 0%, #be7023 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #cd7f32),
    color-stop(100%, #be7023)
  );
  background: -webkit-linear-gradient(top, #cd7f32 0%, #be7023 100%);
  background: -moz-linear-gradient(top, #cd7f32 0%, #be7023 100%);
  background: -o-linear-gradient(top, #cd7f32 0%, #be7023 100%);
  background: -ms-linear-gradient(top, #cd7f32 0%, #be7023 100%);
}
.silver {
  background: #c0c0c0;
  background: linear-gradient(to bottom, #c0c0c0 0%, #b1b1b1 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #c0c0c0),
    color-stop(100%, #b1b1b1)
  );
  background: -webkit-linear-gradient(top, #c0c0c0 0%, #b1b1b1 100%);
  background: -moz-linear-gradient(top, #c0c0c0 0%, #b1b1b1 100%);
  background: -o-linear-gradient(top, #c0c0c0 0%, #b1b1b1 100%);
  background: -ms-linear-gradient(top, #c0c0c0 0%, #b1b1b1 100%);
}
.gold {
  background: #d4af37;
  background: linear-gradient(to bottom, #d4af37 0%, #c5a028 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #d4af37),
    color-stop(100%, #c5a028)
  );
  background: -webkit-linear-gradient(top, #d4af37 0%, #c5a028 100%);
  background: -moz-linear-gradient(top, #d4af37 0%, #c5a028 100%);
  background: -o-linear-gradient(top, #d4af37 0%, #c5a028 100%);
  background: -ms-linear-gradient(top, #d4af37 0%, #c5a028 100%);
}
.diamond {
  background: #264348;
  background: linear-gradient(to bottom, #264348 0%, #173439 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #264348),
    color-stop(100%, #173439)
  );
  background: -webkit-linear-gradient(top, #264348 0%, #173439 100%);
  background: -moz-linear-gradient(top, #264348 0%, #173439 100%);
  background: -o-linear-gradient(top, #264348 0%, #173439 100%);
  background: -ms-linear-gradient(top, #264348 0%, #173439 100%);
}
.pending .card-body {
  filter: grayscale(1);
  opacity: 0.5;
}
.type-pin {
  margin-bottom: 15px;
}
.type-pin img {
  width: 100px;
}
</style>
